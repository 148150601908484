<app-user-status [showBackButton]="true"></app-user-status>

<app-boton-agregar></app-boton-agregar>

<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">
        <div class="col-11 mb-3 aparece">
            <h1>{{ title }}</h1>
        </div>
        <div class="col-11 mb-3 aparece-tres"> 
            <div class="contenedor-tarjetas">
                <div class="tarjetas mt-2 mb-5">
                    <table class="tarjeta tarjeta-light">
                        <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Titular:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ datosPersonales.nombres }} {{ datosPersonales.apellidos }}</p>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Género:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ datosPersonales.genero }}</p>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Fecha Nacimiento:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ datosPersonales.fechanac | date: "dd/MM/yyyy":"GMT+0" }}</p>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Nacionalidad:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ datosPersonales.nacionalidad }}</p>
                            </td>
                        </tr>
                        

                    </table>
                
                </div>
            </div>
        </div>
    </div>
</div>
  


